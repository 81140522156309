@font-face {
    font-family: 'Gilroy';
    src: url('../assets/fonts/gilroy/Gilroy-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../assets/fonts/gilroy/Gilroy-Light.otf');
    font-weight: normal;
    font-style: normal;
}
html {
    font-size: 100%;
}

body {
    margin: 0;
    font-family: "Gilroy", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

:root {
    --primary-color: #13344D;
    --primary-color-accent: #484656;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--primary-color-accent);
    padding: 0;
    margin: 0;
}
::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #f5f5f5;
    padding: 0;
}

.bg-primary {
    background-color: var(--primary-color);
    color: white;
}

.bg-primary-accent {
    background-color: var(--primary-color-accent);
}

.border-primary {
    border-color: #5f5e6b;
}

.text-primary {
    color: var(--primary-color);
}

.black-man-bg {
    background-image: url("./../assets/black_man.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-red {
    color: #fb0a77;
}

.w-72 {
    width: 17rem;
}

.pr-72 {
    padding-right: 17rem;
}
.ml-72 {
    margin-left: 17rem;
}


button,
button:focus {
    outline: none;
}

input {
    outline: none;
    padding-left: 1rem;
}
label {
    display: inline-block;
    padding-bottom: 0.5rem;
}

.progress {
    height: 5px;
    display: block;
    background-color: #fff;
    border-radius: 2px;
    margin: 0 0 1rem 0;
    overflow: hidden;
    position: absolute;
}
.progress .indeterminate {
    background-color: var(--primary-color);
}
.progress .indeterminate:before {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate 2.1s
        cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
        infinite;
}
.progress .indeterminate:after {
    content: "";
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    -webkit-animation: indeterminate-short 2.1s
        cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
        infinite;
    -webkit-animation-delay: 1.15s;
    animation-delay: 1.15s;
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }

    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}


#__react-alert__ > div > div > div {
    width: 100% !important;
    text-transform: none !important;
    border-radius: 7px !important;
    background-color: rgba(255, 255, 255, 1) !important;
    color: black !important;
    box-shadow: 3px 3px 35px 0px #333333ff;
}
#__react-alert__ > div > div > div button svg {
    stroke: black;
}
#__react-alert__ > div > div {
    margin-right: 20px;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}
.main-content-height {
    height: calc(100vh - 6rem);
}
.bg-black-op25 {
    background-color: rgba(0, 0, 0, 0.25);
}

.sidebar .material-icons {
    color: #aaa9b5;
}
button {
    white-space: nowrap;
}
.password-hint::after {
    content: " ";
    /*height: 5px;*/
    /*width: 5px;*/
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid white;
    position: absolute;
    bottom: -10px;
    right: 1px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.custom-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
}

label {
    white-space: nowrap;
}

.setup-header {
    border-collapse: collapse;
}

.custom-table thead th {
    padding: 1.2rem 1rem;
}

.custom-table thead {
    border-bottom: 1px solid #e0e0e0;
    margin: 10px 20px;
}
td {
    text-align: left;
}
.custom-table td {
    padding-top: 2rem;
    text-overflow: ellipsis;
    padding-left: 1rem;
    padding-right: 1rem;
}

.shadow-table {
}
.shadow-table2 tr td,
.shadow-table tr td {
    padding: 1.2rem 1rem;
}

.shadow-table thead tr {
    /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);*/
}

.shadow-table2 tr:nth-child(odd) {
    /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);*/
}

.shadow-table tr:nth-child(even) {
    /*box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);*/
}
.sidebar {
    transition-property: left;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.52, 0.96, 0.6, 1.02);
    left: 0;
}
@media (max-width: 767px) {
    .side-hide {
        left: -400px;
    }
}
/*
.side-tran {
    animation-name: slide-in;
    animation-duration: 0.7s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.52, 0.96, 0.64, 1.26);
}

@keyframes slide-in {
    0% {
        left: -50%;
    }

    100% {
        left: 0;
    }
} */

.bg-green-opaque {
    background-color: #4eb86252;
    color: #4eb862;
}

.dashboard-card {
    position: relative;
    overflow: hidden;
}

.dashboard-card::after {
    content: " ";
    position: absolute;
    right: -10%;
    bottom: -10%;
    z-index: 1;
    opacity: 0.6;
    background-color: #ffffff56;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
}

.dashboard-card::before {
    content: " ";
    position: absolute;
    right: 25px;
    z-index: 2;
    opacity: 0.5;
    bottom: -30%;
    background-color: #ffffff56;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
}
.main-content {
    width: calc(100vw - 16rem);
}

.text-small {
    font-size: 10px;
}

